<template>
  <div>
    <TosListVuetify :entityName="entityName" :headers="headers" :prepareItemFunction="prepareItem">
      <template #customFilters="slotProps">
        <v-container fluid>
          <v-row>
            <v-col cols="2">
              <v-text-field :label="tLabel('uti')" v-model="slotProps.data.uti_codice" clearable/>
            </v-col>
            <v-col cols="3">
              <v-text-field :label="tLabel('merce')" v-model="slotProps.data.merce" clearable/>
            </v-col>
            <v-col cols="3">
              <v-text-field :label="tLabel('caricatore_speditore')" v-model="slotProps.data.caricatoreSpeditore" clearable/>
            </v-col>
            <v-col cols="3">
              <v-select :label="tLabel('Tipo Ingresso')" v-model="slotProps.data.tipoIngresso___EQ" :items="tipoIngressoAll" clearable/>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template #editF="slotProps">
        <v-container fluid v-if="slotProps.data">
          <v-expansion-panels multiple v-model="panels">
            <v-expansion-panel>
              <v-expansion-panel-header> <span>{{ tLabel('Merce') }} </span></v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="4">
                    <v-text-field :label="tLabel('Unit ID')" v-model="slotProps.data.codiceUti" :error-messages="slotProps.errors.codiceUti" />
                  </v-col>
                  <v-col cols="4">
                    <v-select :label="tLabelRequired('Tipo UTI')" v-model.number="slotProps.data.tipoUtiId" item-text="label" item-value="id" :items="tipoUtiList" 
                      :rules="[rules.required]" :error-messages="slotProps.errors.tipoUtiId" 
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field :label="tLabelRequired('Merce')" v-model="slotProps.data.merce" :rules="[rules.required]" :error-messages="slotProps.errors.merce" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2">
                    <v-text-field :label="tLabelRequired('Colli')" v-model.number="slotProps.data.numeroColli" :rules="[rules.required, rules.numeric]" :error-messages="slotProps.errors.numeroColli" />
                  </v-col>
                  <v-col cols="2">
                    <v-text-field :label="tLabelRequired('Quantità merce')" v-model.number="slotProps.data.numeroColliMerce" :rules="[rules.required, rules.numeric]" :error-messages="slotProps.errors.numeroColliMerce" />
                  </v-col>
                  <v-col cols="2">
                    <v-text-field :label="tLabelRequired('Peso Lordo')" v-model.number="slotProps.data.pesoLordo" :rules="[rules.required, rules.numeric]" :error-messages="slotProps.errors.pesoLordo" />
                  </v-col>
                  <v-col cols="2">
                    <v-text-field :label="tLabel('Peso netto')" v-model.number="slotProps.data.pesoNetto" :rules="[rules.numeric]" :error-messages="slotProps.errors.pesoNetto" />
                  </v-col>
                  <v-col cols="2">
                    <v-text-field :label="tLabel('Tara')" v-model.number="slotProps.data.tara"  :rules="[rules.numeric]"  :error-messages="slotProps.errors.tara" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2">
                    <v-text-field :label="tLabel('Lunghezza (mt)')" v-model.number="slotProps.data.lunghezza" :rules="[rules.numeric]" :error-messages="slotProps.errors.lunghezza" />
                  </v-col>
                  <v-col cols="2">
                    <v-text-field :label="tLabel('Larghezza (mt)')" v-model.number="slotProps.data.larghezza" :rules="[rules.numeric]" :error-messages="slotProps.errors.larghezza" />
                  </v-col>
                  <v-col cols="2">
                    <v-text-field :label="tLabel('Altezza (mt)')" v-model.number="slotProps.data.altezza" :rules="[rules.numeric]" :error-messages="slotProps.errors.altezza" />
                  </v-col>

                  <v-col cols="3">
                    <v-text-field :label="tLabel('Sigilli')" v-model="slotProps.data.sigillo" :error-messages="slotProps.errors.sigillo" />
                  </v-col>
                  <v-col cols="3">
                    <v-select :label="tLabel('Full/Empty')" v-model="slotProps.data.fullEmpty" :items="visiteUtiEnumList.fullEmptyList" :error-messages="slotProps.errors.fullEmpty" />
                  </v-col>              
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header> <span>{{ tLabel('Dati commerciali') }} </span></v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="2">
                    <v-text-field :label="tLabelRequired('Mittente')" v-model="slotProps.data.caricatoreSpeditore" :rules="[rules.required]" :error-messages="slotProps.errors.caricatoreSpeditore" />
                  </v-col>
                  <v-col cols="2">
                    <v-text-field :label="tLabel('Polizza')" v-model="slotProps.data.polizza" :error-messages="slotProps.errors.polizza" />
                  </v-col>
                  <v-col cols="2">
                    <v-text-field :label="tLabel('Booking')" v-model="slotProps.data.booking" :error-messages="slotProps.errors.booking"/>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field :label="tLabel('Produttore')" v-model="slotProps.data.produttore" :error-messages="slotProps.errors.produttore" />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field :label="tLabel('Lotto produttore')" v-model="slotProps.data.lottoProduttore" :error-messages="slotProps.errors.lottoProduttore" />
                  </v-col>
                </v-row>
            </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header> <span>{{ tLabel('Ingresso/ Uscita') }} </span></v-expansion-panel-header>
              <v-expansion-panel-content>
              <v-row>
                <v-col cols="3">
                  <v-radio-group :label="tLabelRequired('Tipo Ingresso')" row v-model="slotProps.data.tipoIngresso" :rules="[rules.required]" :error-messages="slotProps.errors.tipoIngresso" >
                    <v-radio label="GATE" value="GATE"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="3">
                  <DateTimeAdapter :label="tLabel('Data / ora ingresso')" v-model="slotProps.data.gateIn" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-radio-group :label="tLabelRequired('Tipo Uscita')" row v-model="slotProps.data.tipoUscita" :rules="[rules.required]" :error-messages="slotProps.errors.tipoUscita">
                    <v-radio label="RAIL" value="RAIL"></v-radio>
                    <v-radio label="GATE" value="GATE"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="3" v-if="slotProps.data.tipoUscita == 'VESSEL'">
                  <v-text-field :label="tLabel('Viaggio nave')" v-model="slotProps.data.viaggioNave" :error-messages="slotProps.errors.viaggioNave" />
                </v-col>
                <v-col cols="3" v-if="slotProps.data.tipoUscita == 'VESSEL'">
                  <v-text-field :label="tLabel('POD')" v-model="slotProps.data.pod" :error-messages="slotProps.errors.pod" />
                </v-col>
                <v-col cols="4" v-if="slotProps.data.tipoUscita == 'RAIL'">
                  <v-select :label="tLabel('Viaggio treno')" clearable v-model="slotProps.data.viaggioUscitaId" :items="trips" item-value="id"
                   :error-messages="slotProps.errors.viaggioUscitaId"
                    @input="(a) => {viaggioUscitaId= a;}" >
                    <template v-slot:item="{ item }">
                      {{ `${ item.codice } - ${ item.nodoArrivo ? item.nodoArrivo.descrizione : 'NA' } - ${ item.etd }` }}
                    </template>
                    <template v-slot:selection="{ item }">
                        {{ `${ item.codice } - ${ item.nodoArrivo ? item.nodoArrivo.descrizione : 'NA' } - ${ item.etd }` }}
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="3" v-if="slotProps.data.tipoUscita == 'RAIL'">
                  <v-select :label="tLabel('Vagone N.')" clearable v-model="slotProps.data.carroUscitaId" :items="wagonList" :error-messages="slotProps.errors.carroUscitaId" />
                </v-col>
                 <v-col cols="3"  v-if="slotProps.data.tipoUscita == 'GATE'">
                  <DateTimeAdapter :label="tLabel('Data / ora uscita')" v-model="slotProps.data.gateOut" />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header> <span>{{ tLabel('Altri dati') }} </span></v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="3">
                    <v-select :label="tLabel('Operatore')" v-model.number="slotProps.data.operatorId" item-text="label" item-value="id" :items="operatorList" :error-messages="slotProps.errors.operatorId" />
                  </v-col>
                  <v-col cols="3">
                    <v-select :label="tLabel('Priorità')" v-model="slotProps.data.priorita" :items="visiteUtiEnumList.prioritaList" :error-messages="slotProps.errors.priorita" />
                  </v-col>   
                  <v-col cols="3">
                    <v-checkbox :label="tLabel('pericoloso')" v-model="slotProps.data.pericoloso" :error-messages="slotProps.errors.pericoloso" />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

        </v-container>
      </template>

      <template #item.actions="slotProps">
        <div class="css-group-and-align-element">
          <v-btn icon title="Modifica" @click="$emit('tableOpenItem', slotProps.data.id)"  :disabled="slotProps.data.tipoIngresso!='GATE'">
            <v-icon color="primary" medium>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon title="Gate IN" @click="openGateDialog(slotProps.data)" :disabled="gateInDisabled(slotProps.data)" >
            <v-icon color="success" medium>mdi-import</v-icon>
          </v-btn>
          <v-btn icon title="Gate OUT" @click="openGateDialog(slotProps.data)" :disabled="gateOutDisabled(slotProps.data) || readonly" >
            <v-icon color="success" medium>mdi-export</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:item.codiceUti="slotProps">
        <b>{{slotProps.value}}</b>
      </template>

      <template #item.tipoIngresso="slotProps">
        <v-icon v-if="slotProps.value=='RAIL'" color="primary">mdi-train</v-icon> 
        <v-icon v-if="slotProps.value=='GATE'" color="primary">mdi-truck</v-icon> 
      </template>

      <template #item.tipoUscita="slotProps">
        <v-icon v-if="slotProps.value=='RAIL'" color="primary">mdi-train</v-icon> 
        <v-icon v-if="slotProps.value=='GATE'" color="primary">mdi-truck</v-icon> 
      </template>
      
    </TosListVuetify>
    
    <v-dialog v-model="showGateInOut" persistent width="600">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
               <v-datetime-picker :label="tLabel('Data / ora ')" v-if="editItem" v-model="editItem.dataOraGate" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="doGate" small v-if="editItem && editItem.gateIn==null">GATE IN</v-btn>
          <v-btn @click="doGate" small v-if="editItem && editItem.gateIn!=null">GATE OUT</v-btn>
          <v-btn @click="showGateInOut = null" small>annulla</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import TosListVuetify from "../../components/anagrafiche/TosListVuetify";
import DateTimeAdapter from "../../components/anagrafiche/DateTimeAdapter";
import FormatsMixins from "../../mixins/FormatsMixins";
import ToastMixin from "../../mixins/ToastMixin";
import moment from 'moment';

export default {
  name: "VisiteUti",
  data() {
    return {
      entityName: "visiteUti",
      operatorList: null,
      tipoUtiList: null,
      visiteUtiEnumList: {},
      trips: [],
      tripWagons: [],
      tipoIngressoAll: [
        { text: "RAIL", value: "RAIL" },
        { text: "GATE", value: "GATE"},
      ],
      panels: [0, 1, 2, 3, 4],
      showGateInOut: null,
      editItem: null,
      viaggioUscitaId: null,
      //dataOraGate: null,
      headers: [
        { text: "UTI", value: "codiceUti"},
        { text: "Tipo Uti", value: "tipoUti", link: "true", columnType:"RTBTipoUti" },
        { text: "Tipo Ingresso", value: "tipoIngresso" },
        { text: "Data In", value: "gateIn" },
        { text: "Data Out", value: "gateOut" },
        { text: "Area", value: "area" },
        { text: "Peso Netto", value: "pesoNetto" },
        { text: "Peso Lordo", value: "pesoLordo" },
        { text: "Tara", value: "tara" },
        { text: "Colli totali", value: "numeroColli" },
        { text: "Colli iniziali", value: "numeroColliIniziali" },
        { text: "Merce", value: "merce" },
        { text: "Polizza", value: "polizza" },
        { text: "Tipo Uscita", value: "tipoUscita" },
        { text: "Viaggio uscita", value: "viaggioUscitaLabel", sortable: false },
      ],
      readonly: false
    };
  },
  components: {
    TosListVuetify,
    DateTimeAdapter,
  },
  mixins: [FormatsMixins, ToastMixin],
  mounted(){
    const loggedUser = this.$store.getters['authentication/loggedUser'];
    this.readonly = loggedUser?.roles?.includes("EXT_TOS_USER");
  },
  computed:{
    wagonList: function () {
     console.log("Set wagons viaggio", this.viaggioUscitaId);
      const list=[];
      if(this.viaggioUscitaId==null) return list;
      let viaggio=this.trips.filter(t=> t.id == this.viaggioUscitaId)[0];
      if(viaggio.listCarri == null) return list;
      viaggio.listCarri.forEach(lc=> list.push({text:lc.visitaCarro.targa, value:lc.id}));
      return list;
    },
  },
  methods: {
    async prepareItem(item){
      if(this.tipoUtiList==null){
         this.tipoUtiList = (await this.$api.get(this.$apiConfiguration.BASE_PATH+"tipiUti/shortList")).data;
         this.operatorList= (await this.$api.get(this.$apiConfiguration.BASE_PATH+"soggetti/shortList")).data;//TODO CategoriaSoggettoEnum.Operatore
         this.visiteUtiEnumList=await this.$api.get(this.$apiConfiguration.BASE_PATH+"visiteUti/visiteUtiEnumList");
         this.visiteUtiEnumList=await this.$api.get(this.$apiConfiguration.BASE_PATH+"visiteUti/visiteUtiEnumList");
         const currDate = moment().format('YYYY-MM-DD');
         const tsDateFrom = this.$utilities.dates.getTsFromDateFormat(moment().add(-1, 'days'));
         const tsDateTo = this.$utilities.dates.getTsFromDateFormat(moment(currDate).add(10, 'days'));
         this.trips = await this.$api.railData.findAllPartenzeProgrammate();
      }
      item.gateOut = item.gateOut ?  moment(item.gateOut).format("YYYY-MM-DDTHH:mm:ss.000Z") : null; //FIX conversion error
      if(item.id==null){
        this.$set(item, 'numeroColli', 1);
        this.$set(item, 'numeroColliMerce', 1);
      }
    },
    gateInDisabled(item){
      return item.gateIn !=null || item.tipoIngresso =='RAIL';
    },
    gateOutDisabled(item){
      return item.gateIn ==null  || item.gateOut!=null;
    },
    async openGateDialog(item){
      this.editItem=await this.$api.get(this.$apiConfiguration.BASE_PATH+"visiteUti/"+item.id);
      this.showGateInOut=true;
      this.editItem.dataOraGate=new Date();
    },
    async doGate(){
      const date=moment(this.editItem.dataOraGate).format("YYYY-MM-DDTHH:mm:ss.000Z");
      console.log("SET ", date);
      if(this.editItem.gateIn==null){
        this.editItem.tipoIngresso='GATE';
        this.editItem.gateIn=date;
        this.editItem.yardGateIn=date;
      } else {
        this.editItem.gateOut=date;
        this.editItem.yardGateOut=date;
        this.editItem.tipoUscita='GATE';
        this.editItem.gateIn=moment(this.editItem.gateIn).format("YYYY-MM-DDTHH:mm:ss.000Z");
      }
      try{
        await this.$api.post(this.$apiConfiguration.BASE_PATH+"visiteUti/edit", this.editItem);
        this.showGateInOut=false;
        this.$emit("refreshTable");
        this.showSuccess("Operazione effettuata correttamente");
      } catch(e){
        console.log("ERRORE",e);
        this.showError("Si è verificato un errore :"+e.message);
      }

    },

  },
};
</script>
